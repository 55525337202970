.match-info-dialog {
  .match-result {
    .result-line {
      display: flex;
      flex-direction: row;
      line-height: 22px;
      padding: 2px;
      margin-bottom: 4px;

      .stat {
        flex: 1 1 0px;

        &.left {
          text-align: right;
          .score-tab {
            margin-left: auto;
          }
          .info {
            margin-left: auto;
          }
        }
        &.name {
          font-size: 18px;
          font-weight: bold;
        }

        &.stat {
          font-weight: bold;
        }

        .info {
          width: 80px;
          height: 36px;
          line-height: 36px;
          text-align: center;
          font-weight: bold;
          font-size: 18px;
          border-radius: 8px;
          border: 1px solid #ccc;
          background-color: #ccc;
          display: block;
        }

        .score-tab {
          width: 100px;
          height: 50px;
          line-height: 50px;
          text-align: center;
          background-color: #525252;
          border: 1px solid #525252;
          color: #fff;
          font-weight: bold;
          font-size: 24px;
          border-radius: 8px;
          display: block;
        }

        input.score-tab, input.info {
          border-color: #ccc;
          color: #333;
          background-color: #fff;
        }
      }


      .delimiter {
        width: 50px;
        line-height: 36px;
        text-align: center;
        font-weight: 300;
        font-size: 14px;
      }

    }
  }
}
