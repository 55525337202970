.bracket-comp {
  .bracket-svg {
    text {
      dominant-baseline: middle;
      text-anchor: middle;

      &.info {
        font-style: italic;
        fill: #aaa;
      }

      &.round-info {
        font-style: italic;
        fill: #aaa;
        text-anchor: start;
      }

      &.winner {
        font-weight: bold;
      }

      &.score {
        fill: #fff;
        font-weight: bold;
      }
      &.average {
        fill: #5d5d5ddd;
      }
    }

    &.scale-0 {
      text {
        font-size: 10px;
      }
    }
    &.scale-1 {
      text {
        font-size: 12px;
      }
    }
    &.scale-2 {
      text {
        font-size: 13px;
      }
    }
  }

  .locate {
    position: fixed;
    bottom: 10px;
    right: 10px;

    .locate-button {
      backdrop-filter: blur(3px);
      background-color: #acacac24;
      height: 44px;
      width: 44px;
      border-radius: 44px;
      overflow: hidden;
    }
  }

  .action-button {
    position: fixed;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
    // background-color: #fff;

    .scale {
      height: 44px;
      overflow: hidden;
      display: flex;
      align-items: center;
      backdrop-filter: blur(3px);
      border-radius: 44px;
      background-color: #acacac24;

      .scale-line {
        display: flex;
      }

      .scale-option {
        width: 24px;
        height: 24px;
        border-radius: 50%;
        border: 4px solid #555;
        // background-color: #fff;
        margin: 0 4px;
        &:after {
          content: "";
          display: block;
          margin-top: 6px;
          margin-left: 16px;
          width: 12px;
          height: 4px;
          background-color: #555;
          position: absolute;
        }

        &.scale-first {
        }
        &.scale-middle {
        }
        &.scale-last {
          &:after {
            content: none;
          }
        }

        .selected {
          background-color: #555;
          position: absolute;
          height: 10px;
          width: 10px;
          border-radius: 50%;
          margin: 3px;
        }
      }
    }
  }
}
