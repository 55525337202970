.home {
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  align-items: center;
  padding: 0 16px;

  .top {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 12px;

    .status {
      text-transform: uppercase;
      padding: 8px 16px;
      border-radius: 8px;
      color: #fff;
      background-color: #999;

      &.registering {
        background-color: #e5aa29;
      }
      &.running {
        background-color: #17a13d;
      }
      &.completed {
        background-color: #1976d2;
      }
    }

    .total-players {
      font-weight: bold;
    }
  }

  .competition-info {
    display: flex;
    flex-direction: row;
    .stats {
      flex: 1 1 0px;
    }
    .box.prizes {
      flex: 0.6 1 0px;
    }
  }

  .progress {
    text-align: center;
    margin-top: 12px;
    position: relative;
    display: inline-block;

    svg {
      transform: rotate(-90deg);
      circle {
        transition: stroke-dashoffset 1s linear;
        stroke: #ffffff33;
        stroke-width: 6px;
      }
      #bar {
        stroke: #ff9f1e;
      }
    }

    .progress-text {
      position: absolute;
      top: 28px;
      width: 80px;
      font-size: 20px;
      font-weight: 100;
    }
  }

  .players {
    .player-row {
      display: flex;
      flex-direction: row;
      height: 32px;
      align-items: center;
      margin-bottom: 2px;

      .player-name {
        flex-grow: 1;
        margin-left: 8px;
      }

      .rank {
        min-width: auto;
        width: 28px;
        height: 28px;
        line-height: 25px;
        padding: 2px;
        border-radius: 4px;
        display: block;
        background-color: #17a13d;
        color: #fff;
        text-align: center;
      }

      .points-info {
        // color: #0000008a;
        margin-left: 4px;
      }
    }
  }
}
