.standings {
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  align-items: center;
  padding: 0 16px;

  .box-label {
    display: flex;
    flex: 1;
    justify-content: space-between;
  }

  .round-select {
    // background-color: #9a9a9a;
  }

  .stats {
    .info-line-big {
      margin: 12px 0;
      position: relative;
    }

    .def {
    }
  }

  .records {
    display: flex;
    justify-content: space-between;
    margin-bottom: 12px;
    padding: 0 2px 0;


    .one80s {
      background-color: #9a9a9a;
      padding: 6px 8px;
      border-radius: 8px;
      color: #fff;
    }

    .hf {
      background-color: #9a9a9a;
      padding: 6px 8px;
      border-radius: 8px;
      color: #fff;
    }
  }

  .players {
    table {
      width: 100%;
      border-collapse: collapse;
      border: 1;

      .table-header {
        background-color: #1976d2;
        height: 36px;
        color: #fff;
        font-weight: bold;
      }
    }
    .player-row {
      height: 36px;
    }

    .rank {
      width: 30px;
      text-align: center;
    }

    .player-bonus {
      width: 48px;
      text-align: center;
    }

    .player-name {
      font-weight: bold;
    }

    .player-points {
      width: 40px;
      text-align: center;
      background-color: #ffe0b6;
    }

    .player-one80s {
      width: 40px;
      text-align: center;
    }

    .player-hf {
      width: 40px;
      text-align: center;
    }
  }
}
