.preloader {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  // box-shadow: inset 60px 0 120px #17a13d, inset -60px 0 120px #1976d2;
  background-color: #1976d2;
  // background-image: linear-gradient(160deg, #0093e9 0%, #80d0c7 100%);

  @keyframes rotation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(359deg);
    }
  }
  .darts {
    position: absolute;
    left: 50%;
    top: 50%;
    margin-left: auto;
    margin-right: auto;

    img {
      margin-left: -50%;
      margin-top: -50%;
      animation: rotation 2s infinite linear;
    }
  }
}
