.header {
  margin-bottom: 24px;

  .menu-item {
    text-decoration: none;
    color: rgb(219, 30, 30);
    font-size: 20px;
    &:hover {
      color: yellow;
      border-bottom: 1px solid white;
    }
  }

  .menu {
    svg {
      fill: #fff;
    }

    a.MuiButtonBase-root {
      height: 50px;
      width: 50px;
      min-width: auto;
      padding: 2px 2px;

      &.selected {
        background-color: #0000003d;
        box-shadow: inset 0 0 8px #00000057;
      }
    }
  }
}

.drawer {
  svg {
    fill: #ccc;
  }
  color: #ccc;
  .MuiPaper-root {
    color: #ccc;
    background-color: #333;
    min-width: 240px;
  }

  .MuiDivider-root {
    border-color: rgb(250 250 250 / 10%);
  }
}
