.bracket-page {
  width: 100vw;
  overflow-x: scroll;
  font-size: 13px;

  .bracket-title {
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    align-items: center;
    padding: 0 16px;
    font-size: 16px;

    .title-text {
      text-transform: uppercase;
      display: inline-block;
      padding: 8px 16px;
      border-radius: 8px;
      color: #fff;
      background-color: #1976d2;
    }
  }

  .info {
    font-size: 16px;
    text-align: center;
  }
}
