.competition-players {
  margin-left: auto;
  margin-right: auto;

  .list-container {
    max-height: 400px;
    overflow-y: scroll;
  }

  .players-list {
    border: 1px solid #e9e9e9;
  }
}

@media only screen and (max-width: 600px) {
  .competition-players {
    .rotate-icons {
      .MuiSvgIcon-root {
        transform: rotate(90deg);
      }
    }
  }
}
